import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from 'simple-react-lightbox'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import MenubarComponent from '../components/MenubarComponent'
import FooterComponent from '../components/FooterComponent'

export default function Cars({ pageContext }) {
  const { car } = pageContext
  const photos = car.zdjecia

  return (
    <>
      <SimpleReactLightbox>
        <div className="overflow-hidden	flex">
          <MenubarComponent />
        </div>
        <div className="flex overflow-hidden mx-auto px-6 md:px-8 xl:px-16 max-w-screen-2xl flex-no-wrap my-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-row-2 md:gap-x-16">
            <div>
              <div className="py-4 flex flex-col	gap-2">
                <div className="flex flex-direction-row justify-between content-center">
                  <h2 className="text-3xl font-bold">
                    {car.marka}
                    <span className="mx-2 text-lg">/</span>
                    <span className="text-lg">{car.model}</span>
                  </h2>

                  <p className="mainColorDark text-base font-bold mt-2">
                    {car.cena}
                    <span className="font-normal"> PLN</span>
                  </p>
                </div>
                <p className="textLight text-sm leading-8">
                  {car.moc} KM | {car.paliwo} | {car.przebieg} KM | {car.rok} |{' '}
                  {car.skrzynia}
                </p>
                <div className="tag-container flex gap-2 text-xs flex-wrap">
                  {car.tagi.map((tag) => (
                    <div className="tag" key={tag}>
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div></div>

            <div>
              <div
                className="opis flex flex-col gap-4 text-justify mb-8 lg:mb-0"
                dangerouslySetInnerHTML={{
                  __html: car.opis.childMarkdownRemark.html,
                }}
              ></div>
            </div>

            <SRLWrapper>
              <div className="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-1 md:gap-2 lg:gap-2">
                {photos.map((photo, key) => (
                  <GatsbyImage
                    className="pointer"
                    key={key}
                    image={getImage(photo)}
                    alt={`mauto ${car.marka} ${car.model} ${car.moc}KM`}
                  />
                ))}
              </div>
            </SRLWrapper>
          </div>
        </div>

        <FooterComponent />
      </SimpleReactLightbox>
    </>
  )
}
